<template>
  <v-card>
    <v-data-table
      :value="selectedDocuments"
      :footer-props="footerProps"
      :headers="headers"
      :items="documents"
      :options.sync="options"
      @item-selected="
        $store.commit('updateSelectedPublicDocuments', {
          items: [$event.item],
          value: $event.value,
        })
      "
      @toggle-select-all="
        $store.commit('updateSelectedPublicDocuments', $event)
      "
      checkbox-color="msaBlue"
      class="public-document-list"
      show-select
    >
      <template v-slot:[`item.name`]="{ item }">
        <a @click="downloadDocument(item)">
          {{ item.name }}
        </a>
      </template>
      <template v-slot:[`item.expiryDate`]="{ item }">
        {{ item.expiryDate | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="editDocument(item)"
              v-on="on"
              v-blur
              icon
              color="msaBlue"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <PublishToggle
          :is-published="item.isPublished"
          @click="togglePublish(item)"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-blur
              @click="deleteDocument(item.id)"
              icon
              color="red"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PublishToggle from '@/components/PublishToggle';

export default {
  name: 'PublicDocumentsList',
  components: {
    PublishToggle,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      documents: [],
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      footerProps: this.$constants.PAGINATION.DEFAULT_FOOTER_PROPS,
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '650',
        },
        {
          text: 'Expiry Date',
          value: 'expiryDate',
          width: '130',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    selectedDocuments() {
      return this.$store.getters.selectedPublicDocuments;
    },
  },
  methods: {
    selectAll(event) {
      if (!event.value) {
        this.$emit('onSelectAll', {
          groupId: this.groupId,
          ...event,
        });
        return;
      }

      this.$emit('onSelectAll', {
        groupId: this.groupId,
        ...event,
      });
      return;
    },
    getDocuments() {
      const url = 'get-public-documents?format=json';
      const params = {
        search: this.search,
        groupId: this.groupId,
        loaderText: 'Loading...',
      };

      this.$axios
        .post(url, params)
        .then((response) => {
          this.documents = response.data;
        })
        .then(() => this.getLabels())
        .catch((error) => error);
    },
    togglePublish(document) {
      const url = document.isPublished
        ? 'unpublish-public-document?format=json'
        : 'publish-public-document?format=json';
      const params = {
        documentId: document.id,
        loaderText: 'Saving...',
      };
      this.$axios
        .post(url, params)
        .then(() => this.getDocuments())
        .catch((error) => error);
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        loaderText: 'Loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-public-document?format=json', params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, doc.originalFilename);
        })
        .catch((error) => error);
    },
    editDocument(document) {
      this.$store.commit('updateSelectedDocument', document);
      this.$router.push({
        name: 'PublicDocumentDetails',
        params: {
          id: document.id,
        },
      });
    },
    deleteDocument(documentId) {
      const confirm = () => {
        const url = 'delete-public-document?format=json';
        const params = {
          documentId,
          loaderText: 'Loading...',
        };
        this.$axios
          .post(url, params)
          .then(() => this.getDocuments())
          .catch((error) => {
            return error;
          });
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this resource?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<style>
.public-document-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
