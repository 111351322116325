<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" md="6">
        <SearchBar
          @search="onSearch($event)"
          ref="searchBar"
          searchLabel="Search by resource name..."
        />
      </v-col>
      <v-col align="right" class="py-0">
        <DocumentGroupDetails
          @close="groupDetailsKey = !groupDetailsKey"
          @submitted="getDocumentGroups()"
          :action="'add'"
          :key="groupDetailsKey"
        />
      </v-col>
      <v-col :class="$vuetify.breakpoint.smAndDown ? '' : 'shrink'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              v-on="on"
              @click="addResource()"
              class="msaBlue white--text"
            >
              <v-icon> mdi-file-document-plus-outline </v-icon>
            </v-btn>
          </template>
          Add Resource
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <PublicDocumentLibraryUpload
          @onReload="
            foldersKey++;
            getDocumentGroups();
          "
          @onSave="uploadKey--"
          :allResourceFolders="documentGroups"
          :key="uploadKey"
        />
      </v-col>
    </v-row>
    <v-row :key="foldersKey">
      <v-col class="pt-0">
        <v-expansion-panels multiple>
          <draggable
            @start="disabled = true"
            @end="
              updatePositions();
              disabled = false;
            "
            :list="documentGroups"
            :disabled="search.length > 0 || $vuetify.breakpoint.smAndDown"
            style="width: 100%"
          >
            <v-expansion-panel
              v-for="(group, index) in documentGroups"
              :disabled="disabled"
              :key="group.id"
              class="msaBlue my-1"
              width="100%"
            >
              <v-expansion-panel-header
                disable-icon-rotate
                :style="group.headerStyle"
                @click="toggleHeaderStyle(index)"
              >
                <template v-slot:default="{ open }">
                  <v-row align="center">
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="shrink">
                      <v-icon :class="open ? 'white--text' : ''">
                        mdi-arrow-all
                      </v-icon>
                    </v-col>
                    <v-col
                      :class="
                        (open ? 'white--text' : '') + ' font-weight-medium'
                      "
                    >
                      {{ group.name }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:actions>
                  <DocumentGroupDetails
                    v-if="group.isExpanded"
                    @submitted="getDocumentGroups()"
                    @close="groupDetailsKey = !groupDetailsKey"
                    :action="'edit'"
                    :groupId="group.id"
                    :groupName="group.name"
                    :key="groupDetailsKey"
                  />
                  <v-tooltip v-if="group.isExpanded" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-blur
                        v-on="on"
                        @click="deleteGroup(group.id)"
                        @click.stop
                        text
                        small
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <PublicDocumentsList
                  @onSelect="onSelect($event)"
                  @onSelectAll="onSelectAll($event)"
                  :search="search"
                  :groupId="group.id"
                  :groups="documentGroups"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <PublicDocumentMassManage
      @onUnselect="unselectDoc($event)"
      @onCancel="onCancelMassManage()"
      :groups="documentGroups"
    />
  </v-container>
</template>

<script>
import Draggable from 'vuedraggable';
import SearchBar from '@/components/SearchBar.vue';
import PublicDocumentsList from '@/components/PublicDocuments/PublicDocumentsList.vue';
import DocumentGroupDetails from '@/components/PublicDocuments/DocumentGroupDetails.vue';
import PublicDocumentMassManage from '@/components/PublicDocuments/PublicDocumentMassManage';
import PublicDocumentLibraryUpload from '@/components/PublicDocuments/PublicDocumentLibraryUpload.vue';

export default {
  name: 'PublicDocuments',
  components: {
    SearchBar,
    Draggable,
    PublicDocumentsList,
    DocumentGroupDetails,
    PublicDocumentMassManage,
    PublicDocumentLibraryUpload,
  },
  data() {
    return {
      disabled: false,
      documentGroups: [],
      groupDetailsKey: false,
      foldersKey: 9999,
      uploadKey: -9999,
      search: '',
    };
  },
  methods: {
    onCancelMassManage() {
      this.$store.commit('updateSelectedPublicDocuments', {
        items: [],
        value: false,
      });
      this.foldersKey++;
    },
    onSearch(event) {
      this.search = event;
      this.getDocumentGroups();
    },
    getDocumentGroups() {
      if (this.search == null) {
        this.search = '';
      }

      const params = {
        search: this.search,
        loaderText: 'Loading...',
      };

      const url = 'get-public-document-groups?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.documentGroups = response.data;
          this.documentGroups.forEach((group) => {
            group.isExpanded = false;
            group.headerStyle = {
              background:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
          });
        })
        .catch((error) => error);
    },
    toggleHeaderStyle(index) {
      const group = { ...this.documentGroups[index] };
      group.isExpanded = !group.isExpanded;
      group.headerStyle = group.isExpanded
        ? {} // msaBlue is the default through the parent's :class attribute
        : {
            background: 'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
          };
      this.$set(this.documentGroups, index, group);
    },
    updatePositions() {
      this.documentGroups.forEach((g, i) => (g.position = i + 1));
      const url = 'update-public-document-group-positions?format=json';
      const params = { groups: this.documentGroups };
      this.$axios.post(url, params).catch((e) => e);
    },
    deleteGroup(groupId) {
      const confirm = () => {
        const url = 'delete-public-document-group?format=json';
        const params = {
          groupId: groupId,
          loaderText: 'Deleting...',
        };
        this.$axios
          .post(url, params)
          .then(() => {
            this.getDocumentGroups();
          })
          .catch((error) => error);
      };

      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this folder?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    addResource() {
      this.$store.commit('updateSelectedDocument', { id: 0, name: 'New File' });
      this.$router.push({
        name: 'PublicDocumentDetails',
        params: {
          id: 0,
        },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateSelectedPublicDocuments', {
      items: [],
      value: false,
    });

    next();
  },
  mounted() {
    if (this.$route.query.name != undefined) {
      this.$refs.searchBar.search = this.$route.query.name;
    }
    this.getDocumentGroups();
  },
};
</script>
