<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="action == 'add'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            data-testid="add-group"
          >
            <v-icon> mdi-folder-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Add Folder</span>
      </v-tooltip>
      <v-tooltip bottom v-if="action == 'edit'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            :data-testid="`edit-group-${groupName}`"
          >
            <v-icon color="white"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text" v-if="action == 'add'">
        Add New Document Folder
      </v-card-title>
      <v-card-title class="msaBlue white--text" v-if="action == 'edit'">
        Edit Document Folder
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="mt-4 font-weight-bold"> Name: </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              class="py-0"
              required
              counter
              maxlength="1000"
              outlined
              v-model="name"
              data-testid="group-name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right" class="grow">
            <v-btn
              plain
              class="msaBlue--text font-weight-bold"
              @click="close()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col align="right" class="shrink">
            <v-btn
              class="msaBlue white--text"
              @click="submit()"
              :disabled="name.length == 0"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DocumentGroupDetails',
  props: {
    action: {
      type: String,
    },
    groupName: {
      type: String,
    },
    groupId: {
      type: Number,
    },
  },
  data() {
    return { name: '', dialog: false, valid: false };
  },
  methods: {
    submit() {
      const url =
        this.groupId == undefined
          ? 'create-public-document-group?format=json'
          : 'edit-public-document-group?format=json';
      const params = {
        name: this.name,
        groupId: this.groupId == undefined ? -1 : this.groupId,
        loaderText: 'Saving...',
      };

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('submitted');
          this.close();
        })
        .catch((error) => error);
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
  mounted() {
    if (this.groupName != undefined) {
      this.name = this.groupName;
    }
  },
};
</script>
<style scoped></style>
