<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...dialog, ...tooltip }"
            v-blur
          >
            <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <span>Upload</span>
      </v-tooltip>
    </template>
    <v-card tile>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6"> Upload Files </v-col>
            <v-col class="shrink pr-6">
              <v-btn color="white" icon @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="pt-4">
        <v-row v-if="!resources.length" align="center">
          <v-col cols="12">
            <v-file-input
              v-model="fileInput"
              @change="onFileInput()"
              dense
              multiple
              outlined
              hide-details
              label="Choose Files"
              prepend-icon="mdi-file-upload-outline"
              accept=".pdf, .doc, .docx, .xlsx, .xls"
            >
            </v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="filesWithBadFileType.length">
          <v-col cols="12">
            <v-alert
              dense
              type="warning"
              dismissible
              @input="filesWithBadFileType = []"
            >
              Only PDF, Word and Excel files are allowed. The following file(s)
              will not be uploaded:
              <ul>
                <li v-for="file in filesWithBadFileType" :key="file.key">
                  {{ file.name }}
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="filesWithBadFileSize.length">
          <v-col cols="12">
            <v-alert
              dense
              type="warning"
              dismissible
              @input="filesWithBadFileSize = []"
            >
              The maximum file size is 100MBs. The following file(s) have
              exceeded this size and will not be uploaded:
              <ul>
                <!-- Files could be in both badType and badSize so I've added the name to ensure the key is unique -->
                <li v-for="file in filesWithBadFileSize" :key="file.key">
                  {{ file.name }}
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="resources.length">
          <v-col cols="12">
            <v-card>
              <v-card-title
                class="msaBlue--text text-body-1 font-weight-medium lightBg"
              >
                <v-row dense align="center">
                  <v-col> Global Settings </v-col>
                </v-row>
              </v-card-title>
              <v-card-text :key="globalRefreshKey" class="pt-4">
                <v-row align="center">
                  <v-col>
                    <CustomizedAutoComplete
                      @change="onGlobalFolder($event)"
                      :value="globalValues.folder"
                      :items="allResourceFolders"
                      item-text="name"
                      item-value="id"
                      label="Select folder"
                      hide-details
                      clearable
                      prepend-icon="mdi-folder-outline"
                    />
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="globalExpiryDateMenu"
                      :nudge-right="40"
                      offset-y
                      min-width="auto"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="onGlobalExpiryDate('', true)"
                          :value="globalValues.expiryDate"
                          dense
                          readonly
                          outlined
                          clearable
                          hide-details
                          prepend-icon="mdi-calendar"
                          label="Pick an expiration date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @change="onGlobalExpiryDate($event)"
                        :min="$helpers.getToday()"
                        no-title
                        scrollable
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-for="resource in resources" :key="resource.id" cols="12">
            <v-card>
              <v-card-title
                class="msaBlue--text text-body-1 font-weight-medium lightBg"
              >
                <v-row dense align="center">
                  <v-col>
                    {{ resource.filename }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      @click="removeResource(resource.id)"
                      icon
                      color="red"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row align="center">
                  <v-col>
                    <CustomizedAutoComplete
                      @change="resource.group = $event"
                      :value="resource.group"
                      :items="allResourceFolders"
                      clearable
                      hide-details
                      label="Select folder"
                      prepend-icon="mdi-folder-outline"
                    />
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="menus[`menu${resource.id}`]"
                      :nudge-right="40"
                      offset-y
                      min-width="auto"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          :value="resource.expiryDate"
                          dense
                          readonly
                          hide-details
                          outlined
                          clearable
                          prepend-icon="mdi-calendar"
                          label="Pick an expiration date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @change="resource.expiryDate = $event"
                        :min="$helpers.getToday()"
                        scrollable
                        no-title
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="height: 40px"> </v-row>
      </v-card-text>
      <v-card-actions
        style="position: fixed; bottom: 0; width: 100%"
        class="msaBlue"
      >
        <v-spacer> </v-spacer>
        <v-btn
          v-if="resources.length > 0"
          @click="close()"
          class="red white--text"
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="resources.length"
          @click="submit()"
          :disabled="isSaveDisabled"
          color="white"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="showLoader"
      z-index="99999"
      class="text-h6"
      opacity="0.8"
    >
      <v-row align="center">
        <v-col cols="12" align="center">
          <v-progress-circular
            indeterminate
            color="#3564ac"
          ></v-progress-circular>
        </v-col>
        <v-col cols="12">
          {{ loaderText }}
        </v-col>
      </v-row>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {
  name: 'PublicDocumentLibraryUpload',
  data() {
    return {
      acceptedMimeTypes: [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
      dialog: false,
      failedToUpload: [],
      fileInput: [],
      filesWithBadFileSize: [],
      filesWithBadFileType: [],
      globalExpiryDateMenu: false,
      globalRefreshKey: 9999,
      globalValues: {
        groups: undefined,
        expiryDate: '',
        sendEmail: false,
      },
      loaderText: '',
      resources: [],
      showLoader: false,
      menus: {},
    };
  },
  props: {
    allResourceFolders: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getDataForUpload() {},
    onFileInput() {
      this.fileInput.forEach((file) => {
        const maxFileSizeInBytes = this.$constants.MAX_FILE_SIZE['100MB'];

        const isGoodFileSize = file.size <= maxFileSizeInBytes && file.size > 0;
        const isGoodFileType = this.acceptedMimeTypes.includes(file.type);

        if (isGoodFileType && isGoodFileSize) {
          const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

          if (nameWithoutExtension != '') {
            this.resources.push({
              id: this.$helpers.createGuid(),
              name: nameWithoutExtension,
              file: file,
              filename: file.name,
              type: file.type,
              menu: false,
              error: false,
              group: this.globalValues.groups,
              expiryDate: this.globalValues.expiryDate,
            });
          } else {
            this.filesWithBadFileType.push(file.name);
          }
        } else {
          if (!isGoodFileType) {
            file.key = this.$helpers.createGuid(); // Used for v-alert
            this.filesWithBadFileType.push(file);
          }

          if (!isGoodFileSize) {
            file.key = this.$helpers.createGuid(); // Used for v-alert
            this.filesWithBadFileSize.push(file);
          }
        }
      });
      this.fileInput = [];
    },
    onGlobalFolder(folder) {
      this.globalValues.folder = folder;

      this.resources.forEach((resource) => {
        resource.group = folder;
      });
    },
    onGlobalExpiryDate(date) {
      this.globalValues.expiryDate = date;

      this.resources.forEach((resource) => {
        resource.expiryDate = date;
      });
    },
    removeResource(id) {
      this.resources = this.resources.filter((resource) => resource.id != id);

      if (!this.resources.length) {
        this.dialog = false;
      }
    },
    close(showMessage = true) {
      this.filesWithBadFileSize = [];
      this.filesWithBadFileType = [];

      const confirm = () => {
        this.dialog = false;
        this.resources = [];

        this.globalValues = {
          groups: undefined,
          expiryDate: null,
        };
      };

      if (this.resources.length && showMessage) {
        this.$root.showMessage(
          'Cancel upload',
          'Are you sure you want to cancel? This will result in the file(s) not being uploaded.',
          () => confirm(),
          () => false,
          'Confirm',
          'Cancel',
        );
        return;
      }

      if (!showMessage) {
        this.$emit('onReload');
      }

      this.dialog = false;
    },
    async submit() {
      if (this.isSaveDisabled) {
        return;
      }
      this.showLoader = true;
      for (let i = 0; i < this.resources.length; i++) {
        this.resources[i].guid = this.$helpers.createGuid();
        await this.uploadResource(this.resources[i], i).then((result) => {
          if (!result) {
            this.failedToUpload.push(this.resources[i]);
          }
        });
      }

      if (this.failedToUpload.length) {
        this.loaderText = `${this.failedToUpload.length} document(s) failed to upload. `;
        setTimeout(() => {
          this.showLoader = false;
          this.dialog = false;
        }, 3000);
        return;
      }

      this.showLoader = false;
      this.$emit('onSave');
      this.close(false);
    },
    async uploadResource(resource, index) {
      this.loaderText = `Uploading file (${index + 1}/${
        this.resources.length
      })...`;
      const url = 'add-public-document?format=json';
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      // Clearable sometimes makes value null on v-date-picker/v-text-field. Prevents null to DateObject failure on backend
      if (resource.expiryDate == null) {
        resource.expiryDate = '';
      }

      const params = new FormData();
      params.append('file', resource.file);
      params.append('name', resource.name);
      params.append('publicLibraryGroupId', resource.group);
      params.append('originalFilename', resource.filename);
      params.append('isPublished', 0);
      params.append('mimeType', resource.type);
      params.append('size', resource.fileSize);
      params.append('expiryDate', resource.expiryDate);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);

      return this.$axios.post(url, params, headers).catch((error) => error);
    },
  },
  computed: {
    isSaveDisabled() {
      return !!this.resources.find((resource) => resource.group == undefined);
    },
  },
};
</script>
